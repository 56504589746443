import { defineStore } from "pinia"
import type { StoreTypes } from "~/types/store"
import Http from "~/request/server"
import type { HttpUseFetch } from "~/types/http"
export const useCard = defineStore("card", {
  state: (): StoreTypes.Card<any> => ({
    cardList: [],
    configList: [],
    withdrawConfig: [],
    // 4 PIX 5 G-CASH
    parseJson: [4, 5], // 需要解析的transfer类型 categoryTransfer
    rates: {} as any,
  }),
  actions: {
    GET_USER_CARD(params = {}) {
      return new Promise((resolve, reject) => {
        Http.post("withdrawalAddressList", params)
          .then((res: HttpUseFetch.Response) => {
            if (res.code === 0) {
              this.cardList = res.data.map((item: any) => {
                let title = ""
                if (
                  [3, 5, 6, 8, 9, 12].includes(item.categoryTransfer) &&
                  item.address
                ) {
                  try {
                    title = JSON.parse(item.address)?.accountNumber
                  } catch (e) {
                    console.log(e)
                  }
                }
                if (item.categoryTransfer === 4 && item.address) {
                  try {
                    title = JSON.parse(item.address)?.accountNo
                  } catch (e) {
                    console.log(e)
                  }
                }
                if (item.categoryTransfer === 20 && item.address) {
                  try {
                    const address = JSON.parse(item.address)
                    item.addressCurrency = address.currency
                    item.addr = address.address
                  } catch (e) {
                    console.log(e)
                  }
                }
                item.title = title

                return item
              })
            }
            resolve(res)
          })
          .catch((error) => reject(error))
      })
    },
    GET_PLAT_CONFIG() {
      Http.post("getPlatConfigList").then((res: HttpUseFetch.Response) => {
        if (res.code === 0) this.configList = res.data
      })
    },
    async CREATE_CARD(params = {}) {
      try {
        const { code } = await Http.post("addWithdrawalAddress", params)
        if (code === 0) this.GET_USER_CARD()
        return Promise.resolve(code)
      } catch (e) {}
    },
    GET_WITHDRAW_CONFIG(params = {}) {
      return new Promise((resolve, reject) => {
        Http.post("getWithdrawalChannelList", params)
          .then((res: HttpUseFetch.Response) => {
            if (res.code === 0) this.withdrawConfig = res.data
            resolve(res)
          })
          .catch((error) => reject(error))
      })
    },
    GET_RATES() {
      return new Promise((resolve, reject) => {
        Http.post("currencyRateApi")
          .then((res: HttpUseFetch.Response) => {
            if (res.code === 0) this.rates = res.data
            resolve(res)
          })
          .catch((error) => reject(error))
      })
    },
  },
})
